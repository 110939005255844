<template>
  <v-container fill-height fluid>

    <v-row justify="center">
      <v-col cols="5" align-self="center">
        <h2 class="mt-4 grey--text text--darken-1">Schedule list</h2>
      </v-col>
    </v-row>

    <!--date pickers-->
    <v-row justify="center">
      <v-col cols="4">
        <v-menu v-model="fromDatePickerMenuToggle" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="fromDatePicker" label="From date" prepend-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on" style="width: 200px"/>
          </template>
          <v-date-picker v-model="fromDatePicker" @input="fromDatePickerMenuToggle = false; loadReceipts()"/>
        </v-menu>
      </v-col>
      <v-col cols="4">
        <v-menu v-model="toDatePickerMenuToggle" :close-on-content-click="false" :nudge-right="40"
                offset-y transition="scale-transition" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="toDatePicker" label="To date" prepend-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on" style="width: 200px"/>
          </template>
          <v-date-picker v-model="toDatePicker" @input="toDatePickerMenuToggle = false; loadReceipts()"/>
        </v-menu>
      </v-col>
      <v-col cols="1">
        <v-icon class="mt-4">mdi-progress-download</v-icon>
      </v-col>
    </v-row>

    <!--sale and purchase panels-->
    <v-row justify="center" v-if="receipts.length > 0">
      <v-expansion-panels inset>
        <!--sale panel-->
        <v-expansion-panel v-if="coinSales.length > 0">
          <v-expansion-panel-header>
            <v-chip color="orange" outlined small class="ml-1" style="max-width: fit-content;">Total shitje</v-chip>
            <v-chip v-if="totalSaleALL.length > 0" color="indigo darken-3" outlined small class="ml-3"
                    style="max-width: fit-content;">{{ totalSaleALL }} ALL
            </v-chip>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-chip-group active-class="amber darken-3 accent-4 white--text" column>
              <v-chip v-for="coin in coinSales" :key="coin.name" outlined color="orange darken-2">
                {{ coin.name }}<strong style="margin-left: 4px;font-size: 1.1rem;">{{ coin.total }}</strong></v-chip>
            </v-chip-group>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!--purchase panel-->
        <v-expansion-panel v-if="coinPurchases.length > 0">
          <v-expansion-panel-header>
            <v-chip color="green" outlined small class="ml-1" style="max-width: fit-content;">Total blerje</v-chip>
            <v-chip v-if="totalPurchaseALL.length > 0" color="indigo darken-3" outlined small class="ml-3"
                    style="max-width: fit-content;">{{ totalPurchaseALL }} ALL
            </v-chip>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-chip-group active-class="amber darken-3 accent-4 white--text" column>
              <v-chip v-for="coin in coinPurchases" :key="coin.name" outlined color="green darken-2">
                {{ coin.name }}<strong style="margin-left: 4px; font-size: 1.1rem;">{{ coin.total }}</strong></v-chip>
            </v-chip-group>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <v-row v-if="receipts.length > 0" justify="center">
      <v-col align-self="center">
        <v-btn outlined small @click="download">
          <v-icon left>
            mdi-progress-download
          </v-icon>
          Shkarko listën
        </v-btn>
      </v-col>
    </v-row>

    <!--receipts table-->
    <v-row justify="center" align="center" class="mt-2">
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-center">Worker</th>
              <th class="text-left">Date</th>
              <th class="text-center">Start</th>
              <th class="text-left">End</th>
              <th class="text-left">Total hours</th>
              <th class="text-left">Confirmed by</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="text-center">Amarildo</td>
              <td>18-06-2023 08:00</td>
              <td class="text-center">08:10</td>
              <td>16:30</td>
              <td>8:20</td>
              <td>Administratori</td>
            </tr>
<!--            <tr v-for="receipt in receipts" :key="receipt.id">
              <td class="text-center">{{ receipt.dateTime }}</td>
              <td>{{ receipt.user.username }}</td>
              <td class="text-center">
                <v-chip v-if="receipt.isSale" class="ma-2" color="orange" outlined>Shitje</v-chip>
                <v-chip v-else class="ma-2" color="green" outlined>Blerje</v-chip>
              </td>
              <td>{{ receipt.coin.name }}</td>
              <td>{{ receipt.rate }}</td>
              <td>{{ receipt.amount }}</td>
              <td>{{ receipt.commission }}</td>
              <td>
                <v-btn class="mx-2" fab small color="grey lighten-3" depressed @click="printReceipt(receipt)">
                  <v-icon color="grey darken-2">mdi-printer</v-icon>
                </v-btn>
              </td>
            </tr>-->
            </tbody>
          </template>
        </v-simple-table>

      </v-col>
    </v-row>

    <!--error if no receipts are found-->
    <v-row v-if="noReceiptsFound" justify="center">
      <v-alert :value="true" type="error" style="width: fit-content" dismissible>{{ noReceiptsFoundText }}</v-alert>
    </v-row>
    <!--if any error happened-->
    <v-row v-if="errorMessage.length > 0" justify="center">
      <v-alert :value="true" type="error" style="width: fit-content" dismissible>{{ errorMessage }}</v-alert>
    </v-row>

    <v-overlay :value="showOverlay" color="#B4B4B4">
      <v-progress-circular indeterminate size="50" color="amber"/>
    </v-overlay>

  </v-container>
</template>

<script>
import {BackendError} from "@/exceptions/BackendError";
import ReceiptService from "@/service/ScheduleService";
import moment from "moment";

export default {
  name: "ScheduleFilterComponent",

  data: () => ({
    noReceiptsFound: false,
    showOverlay: true,

    noReceiptsFoundText: 'No schedule found!',
    errorMessage: '',

    fromDatePickerMenuToggle: '',
    toDatePickerMenuToggle: '',
    fromDatePicker: '',
    toDatePicker: '',

    receipts: [],
    coinSales: [],
    coinPurchases: [],
    totalPurchaseALL: 0,
    totalSaleALL: 0,

    receiptToPrint: null,
    showReceiptPrintDialog: false
  }),

  created() {
    this.initialLoadReceipts();
  },

  methods: {

    /**
     * filter receipts created on current day, call coin total method on finish
     */
    async initialLoadReceipts() {
      let receiptsResponse;
      try {
        receiptsResponse = await ReceiptService.filter(moment().format('yy-MM-DD'),
            moment().format('yy-MM-DD'));
      } catch (e) {
        if (e instanceof BackendError) {
          this.errorMessage = e.message;
          return;
        }
        this.errorMessage = "Problem during request handling!";
        return;
      } finally {
        this.showOverlay = false;
      }
      if (receiptsResponse.length !== 0) {
        this.receipts = receiptsResponse;
        await this.loadCoinTotal(moment().format('yy-MM-DD'), moment().format('yy-MM-DD'));
      }
    },

    /**
     * filter receipts based on datepicker value, call coin total method on finish
     */
    async loadReceipts() {
      this.resetValues();
      if (this.fromDatePicker === '' || this.toDatePicker === '') {
        return;
      }
      this.errorMessage = '';
      this.showOverlay = true;
      this.receipts = [];
      let receiptsResponse;
      try {
        receiptsResponse = await ReceiptService.filter(this.fromDatePicker, this.toDatePicker);
      } catch (e) {
        if (e instanceof BackendError) {
          this.errorMessage = e.message;
          return;
        }
        this.errorMessage = "Problem during request handling!";
        return;
      } finally {
        this.showOverlay = false;
      }
      if (receiptsResponse.length === 0) {
        this.receipts = receiptsResponse; //clear list
        this.noReceiptsFound = true;
      } else {
        this.receipts = receiptsResponse;
        this.noReceiptsFound = false;
        await this.loadCoinTotal(this.fromDatePicker, this.toDatePicker);
      }
    },

    /**
     * load total sales and purchases for each coin, show sum in ALL
     * @param fromDate
     * @param toDate
     * @returns {Promise<void>}
     */
    async loadCoinTotal(fromDate, toDate) {
      let apiResponse;
      try {
        apiResponse = await ReceiptService.getCoinTotals(fromDate, toDate);
      } catch (e) {
        if (e instanceof BackendError) {
          this.errorMessage = e.message;
          return;
        }
        return;
      }
      this.coinPurchases = apiResponse.purchases;
      this.totalPurchaseALL = apiResponse.totalPurchaseALL.toLocaleString();
      this.coinSales = apiResponse.sales;
      this.totalSaleALL = apiResponse.totalSaleALL.toLocaleString();
    },

    async download() {
      try {
        const apiResponse = await ReceiptService.download(this.fromDatePicker, this.toDatePicker);
        const linkSource = `data:application/pdf;base64,${apiResponse.data}`;
        const downloadLink = document.createElement("a");
        const fileName = "faturat-exchange.xlsx";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } catch (e) {
        if (e instanceof BackendError) {
          this.errorMessage = e.message;
        }
        this.errorMessage = "Problem gjatë shkarkimit të listës!";
      }
    },

    dialogClosedEvent() {
      this.receiptToPrint = null;
    },

    /**
     * clears current filtered values, call this function before filtering receipts
     */
    resetValues() {
      this.totalSaleALL = '';
      this.totalPurchaseALL = '';
      this.coinPurchases = [];
      this.coinSales = [];
    },

    printReceipt(receipt) {
      this.receiptToPrint = receipt;
      this.showReceiptPrintDialog = true;
    }
  }
}
</script>